import { all, fork, put, takeEvery, call, SagaReturnType } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  fetchTenantConfig as fetchTenantConfigAPI
} from "helpers";

import { themeApiResponseSuccess, themeApiResponseError } from "./actions";
import { ThemeActionTypes } from "./constants";

function* fetchConfig(): SagaIterator {
  try {
    const response = yield call(fetchTenantConfigAPI);
    if (response && !response.errors) {
      yield put(themeApiResponseSuccess(ThemeActionTypes.SET_THEME, response));
    } else {
      yield put(themeApiResponseError(ThemeActionTypes.SET_THEME, "Tenant not found"));
    }
  } catch (error: any) {
    yield put(themeApiResponseError(ThemeActionTypes.SET_THEME, error));
  }
}


export function* watchFetchConfig() {
  yield takeEvery(ThemeActionTypes.SET_THEME, fetchConfig);
}


function* themeSaga() {
  yield all([fork(watchFetchConfig)]);
}

export default themeSaga;
