import { APICore } from 'helpers/api/apiCore';
import { userInfo } from 'os';
import { AuthActionTypes } from './constants';

const api = new APICore();

const INIT_STATE = {
  user: api.getLoggedInUser(),
  loading: false,
  authError: '',
  metricsError: '',
};

type FleetUserData = {
  id: number,
  email: string,
  first_name: string,
  last_name: string,
  phone: string,
  auth_token: string,
  fleet_app_name: string,
  fleet_app_id: number,
  has_logo: boolean | string,
  setting: {
    address_entry: boolean | string,
    booking_model: boolean | string,
    create_branches: boolean | string,
    military_time: boolean | string,
    scheduling_select: boolean | string,
    vehicle_search: boolean | string,
    view_appointments_report: boolean | string
  },
  metrics: {
    appointment_this_week: number,
    total_vehicles: number,
    total_branches: number,
    total_appointments: number,
    four_week_total: number,
    fleet_events: FleetEvent[],
    pending_estimates: number,
  }
  service_select: string,
};

type FleetEvent = {
  id: number,
  start_at: string,
  branch: string,
  street: string,
  street_adtl: string,
  city: string,
  state_abbr: string,
  postal: string,

}

type AuthActionType = {
  type:
  | AuthActionTypes.API_RESPONSE_SUCCESS
  | AuthActionTypes.API_RESPONSE_ERROR
  | AuthActionTypes.LOGIN_USER
  | AuthActionTypes.SIGNUP_USER
  | AuthActionTypes.LOGOUT_USER
  | AuthActionTypes.RESET
  | AuthActionTypes.SET_AUTH_LOADING
  | AuthActionTypes.FETCH_USER;
  payload: {
    actionType?: string;
    data?: FleetUserData;
    error?: string;
    id: string;
  };
};

type State = {
  user?: FleetUserData;
  loading?: boolean;
  value?: boolean;
  authError: string;
  metricsError: string;
};

const Auth = (state: State = INIT_STATE, action: AuthActionType) => {
  switch (action.type) {
    case AuthActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            user: action.payload.data,
            userLoggedIn: true,
            loading: false,
            error: false
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          return {
            ...state,
            userSignUp: true,
            loading: false,
          };
        }
        case AuthActionTypes.LOGOUT_USER: {
          return {
            ...INIT_STATE
          };
        }
        case AuthActionTypes.FETCH_USER:
          let currentUser = { ...state.user }
          currentUser.email = action.payload.data?.email
          currentUser.phone = action.payload.data?.phone
          currentUser.first_name = action.payload.data?.first_name
          currentUser.last_name = action.payload.data?.last_name
          api.setLoggedInUser(currentUser);
          return {
            ...state,
            user: { ...currentUser },
            loading: false,
          };
        case AuthActionTypes.FETCH_METRICS:
          let updateUser = { ...state.user, metrics: action.payload.data }
          return {
            ...state,
            user: updateUser,
            loading: false,
          };
        default:
          return { ...state };
      }

    case AuthActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthActionTypes.LOGIN_USER: {
          return {
            ...state,
            authError: action.payload.error,
            userLoggedIn: false,
            loading: false,
          };
        }
        case AuthActionTypes.SIGNUP_USER: {
          return {
            ...state,
            error: true,
            errorMsg: action.payload.error,
            userSignUp: false,
            loading: false,
          };
        }
        case AuthActionTypes.FETCH_USER:
          return {
            ...state,
            authError: action.payload.error,
            loading: false,
          };
        case AuthActionTypes.FETCH_METRICS:
          return {
            ...state,
            error: true,
            errorMsg: action.payload.error,
            loading: false,
          };
        default:
          return { ...state };
      }
    case AuthActionTypes.SET_AUTH_LOADING:
      return {
        ...state,
        loading: true
      }
    case AuthActionTypes.RESET:
      return {
        ...INIT_STATE,
        loading: false,
        error: false,
        userSignUp: false,
        userLoggedIn: false,
        passwordReset: false,
        passwordChange: false,
      };

    default:
      return { ...state };
  }
};

export default Auth;
