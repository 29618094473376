export enum AuthActionTypes {
  API_RESPONSE_SUCCESS = '@@auth/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@auth/API_RESPONSE_ERROR',

  LOGIN_USER = '@@auth/LOGIN_USER',
  LOGOUT_USER = '@@auth/LOGOUT_USER',
  SIGNUP_USER = '@@auth/SIGNUP_USER',
  FETCH_USER = '@@auth/FETCH_USER',
  FETCH_METRICS = '@@auth/FETCH_METRICS',
  SET_AUTH_LOADING = '@@auth/SET_AUTH_LOADING',

  RESET = '@@auth/RESET',
}
