import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Branches from './branches/reducers';
import Appointments from './appointments/reducers'
import Reports from './reports/reducers';
import Theme from './theme/reducers';
import Invoices from './invoices/reducers'

export default combineReducers({
  Auth,
  Layout,
  Branches,
  Appointments,
  Reports,
  Theme,
  Invoices
});
