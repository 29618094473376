const getPrimaryColor = () => {
  return localStorage.getItem("primaryColor") ?? "#134270"
}

const getSecondaryColor = () => {
  return localStorage.getItem("secondaryColor") ?? "#3A76BA0"
}

const getbackgroundColor = () => {
  return localStorage.getItem("backgroundColor") ?? "#3A76BA0"
}

const getprimaryButtonColor = () => {
  return localStorage.getItem("primaryButtonColor") ?? "#3A76BA0"
}
const getsecondaryButtonColor = () => {
  return localStorage.getItem("secondaryButtonColor") ?? "#5BBA76"
}

const getprimaryButtonTextColor = () => {
  return localStorage.getItem("primaryButtonTextColor") ?? "#EEEEEE"
}

const getsecondaryButtonTextColor = () => {
  return localStorage.getItem("secondaryButtonTextColor") ?? "#EEEEEE"
}

const getCompanyName = () => {
  return localStorage.getItem("companyName") ?? "Spiffy"
}

// default to spiffy?
const getLogoUrl = () => {
  return localStorage.getItem("logoUrl") ?? ""
}

const getIconUrl = () => {
  return localStorage.getItem("iconUrl") ?? ""
}

const getButtonLogoUrl = () => {
  return localStorage.getItem("buttonIconUrl") ?? ""
}

const getStripeKey = () => {
  return localStorage.getItem("stripeKey") ?? ""
}

const getSubdomain = () => {
  return localStorage.getItem("subdomain") ?? ""
}

const getDomain = () => {
  return localStorage.getItem("domain") ?? ""
}

// Using this function will set the variables without causing a rerender like using redux would
const setLocalStorageVariables = (company_name: string, logo: string, icon: string, button_logo: string, stripe_key: string, subdomain: string, domain: string, fleet_portal_customization: any) => {

  localStorage.setItem("companyName", company_name)
  localStorage.setItem("logoUrl", logo)
  localStorage.setItem("iconUrl", icon)
  localStorage.setItem("buttonIconUrl", button_logo)
  localStorage.setItem("stripeKey", stripe_key)
  localStorage.setItem("subdomain", subdomain)
  localStorage.setItem("domain", domain)
  localStorage.setItem("FleetPortalCustomization", fleet_portal_customization)
}

const clearTenantLocalStorageVariables = () => {
  localStorage.clear()
}


export { setLocalStorageVariables, getLogoUrl, getIconUrl, getButtonLogoUrl, getCompanyName, getPrimaryColor, getSecondaryColor, getbackgroundColor, getprimaryButtonColor, getsecondaryButtonColor, getprimaryButtonTextColor, getsecondaryButtonTextColor, clearTenantLocalStorageVariables, getStripeKey, getSubdomain, getDomain }