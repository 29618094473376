import { all, fork, put, takeEvery, call, take } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  fetchInvoices as fetchInvoiceAPI,
} from 'helpers';
import { invoicesApiResponseSuccess, invoicesApiResponseError, setLoadingInvoices } from './actions';
import { InvoiceActionTypes } from './constants';
import { Appointment } from 'pages/dashboard/FleetBranchTypes';

type InvoiceData = {
  payload: {
    fleet_branch_id: string
  },
  type: string
}

function* fetchInvoices({ payload: { fleet_branch_id } }: InvoiceData): SagaIterator {
  try {
    yield put(setLoadingInvoices())
    const response = yield call(fetchInvoiceAPI, fleet_branch_id);
    const invoiceResponse = response.data;
    // const invoiceResponse = response.data["invoices"];
    // invoiceResponse.forEach((invoice: Invoice) => {
    //   // Start Date
    //   let tempDate = new Date(appointment.start);
    //   appointment.start = tempDate;
    //   // End Date
    //   tempDate = new Date(appointment.end);
    //   appointment.end = tempDate;
    // });
    yield put(invoicesApiResponseSuccess(InvoiceActionTypes.INVOICES, invoiceResponse));
  } catch (error: any) {
    yield put(invoicesApiResponseError(InvoiceActionTypes.INVOICES, error));
  }
}

export function* watchInvoices() {
  yield takeEvery(InvoiceActionTypes.INVOICES, fetchInvoices);
}

function* invoiceSaga() {
  yield all([fork(watchInvoices),]);
}

export default invoiceSaga;
