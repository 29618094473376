export enum BranchActionTypes {
  API_RESPONSE_SUCCESS = '@@fleet/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@fleet/API_RESPONSE_ERROR',

  RESET = '@@fleet/RESET',
  FETCH_BRANCHES = '@@fleet/FETCH_BRANCHES',
  FETCH_ALL_PACKAGES = '@@fleet/FETCH_ALL_PACKAGES',
  SET_SELECTED_BRANCH = '@@fleet/SET_SELECTED_BRANCH'

}
