import { all, fork, put, takeEvery, call, SagaReturnType } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import {
  fetchBranchesPackages as fetchBranchesPackagesAPI,
  fetchVehiclesReport as fetchVehiclesReportAPI,
  fetchAppointmentsReport as fetchAppointmentsReportAPI
} from "helpers";


import { vehiclesReportApiResponseSuccess, vehiclesReportApiResponseError } from "./actions";
import { ReportsActionTypes } from "./constants";
import { Console } from "console";

type ReportData = {
  payload: {
    from: string;
    to: string;
    branches: string[];
    packages: string[];
    vin: string;
    unit: string;
    make: string;
    model: string;
    service: string;
    appointmentId: string;
    toDate: string;
    fromDate: string;
  };
  type: string;
}

function* fetchBranchesPackages(): SagaIterator {
  try {
    const response = yield call(fetchBranchesPackagesAPI);
    const branchesResponse = response.data["fleet_branches_packages"]["fleet_branches"]
    const packagesResponse = response.data["fleet_branches_packages"]["packages"]
    yield put(vehiclesReportApiResponseSuccess(ReportsActionTypes.FETCH_BRANCHES, branchesResponse));
    yield put(vehiclesReportApiResponseSuccess(ReportsActionTypes.FETCH_PACKAGES, packagesResponse));
  } catch (error: any) {
    yield put(vehiclesReportApiResponseError(ReportsActionTypes.FETCH_BRANCHES, error));
    yield put(vehiclesReportApiResponseError(ReportsActionTypes.FETCH_PACKAGES, error));
  }
}

function* fetchVehiclesReport({ payload: { toDate, fromDate, make, model, vin, unit, service, appointmentId, branches, packages } }: ReportData): SagaIterator {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  try {
    const response = yield call(fetchVehiclesReportAPI, toDate, fromDate, make, model, vin, unit, service, appointmentId, branches, packages);
    let reportResponse;
    if (response !== undefined) {
      reportResponse = response["vehicles"]
      //format total amount
      for (let i = 0; i < reportResponse.length; i++) {
        let stringTotal = reportResponse[i].service_total
        stringTotal = parseFloat(stringTotal)
        stringTotal /= 100
        stringTotal = formatter.format(stringTotal)
        reportResponse[i].service_total = stringTotal.toString();

      }
    }
    yield put(vehiclesReportApiResponseSuccess(ReportsActionTypes.FETCH_VEHICLE_REPORT, reportResponse));
  } catch (error: any) {
    yield put(vehiclesReportApiResponseError(ReportsActionTypes.FETCH_VEHICLE_REPORT, error));
  }
}

function* fetchAppointmentsReport({ payload: { toDate, fromDate, make, model, vin, unit, service, appointmentId, branches, packages } }: ReportData): SagaIterator {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  try {
    const response = yield call(fetchAppointmentsReportAPI, toDate, fromDate, make, model, vin, unit, service, appointmentId, branches, packages);
    let reportResponse;
    if (response !== undefined) {
      reportResponse = response["services_report"]
      //format total amount
      for (let i = 0; i < reportResponse.length; i++) {
        let stringTotal = reportResponse[i].service_total
        stringTotal = parseFloat(stringTotal)
        stringTotal /= 100
        stringTotal = formatter.format(stringTotal)
        reportResponse[i].service_total = stringTotal.toString();

      }
    }
    yield put(vehiclesReportApiResponseSuccess(ReportsActionTypes.FETCH_APPOINTMENTS_REPORT, reportResponse));
  } catch (error: any) {
    yield put(vehiclesReportApiResponseError(ReportsActionTypes.FETCH_APPOINTMENTS_REPORT, error));
  }
}

export function* watchVehiclesReport() {
  yield takeEvery(ReportsActionTypes.FETCH_VEHICLE_REPORT, fetchVehiclesReport);
}

export function* watchBranches() {
  yield takeEvery(ReportsActionTypes.FETCH_BRANCHES, fetchBranchesPackages);
}

export function* watchPackages() {
  yield takeEvery(ReportsActionTypes.FETCH_PACKAGES, fetchBranchesPackages);
}

export function* watchAppointmentsReport() {
  yield takeEvery(ReportsActionTypes.FETCH_APPOINTMENTS_REPORT, fetchAppointmentsReport);
}

function* reportSaga() {
  yield all([fork(watchBranches), fork(watchPackages), fork(watchVehiclesReport), fork(watchAppointmentsReport)]);
}

export default reportSaga;
