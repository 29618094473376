

import { ThemeActionTypes } from './constants';
import { getCompanyName, clearTenantLocalStorageVariables, getLogoUrl, getIconUrl, getButtonLogoUrl, getStripeKey, getSubdomain, setLocalStorageVariables } from './helper';


const INIT_STATE: State = {
  companyName: getCompanyName(),
  logoUrl: getLogoUrl(),
  iconUrl: getIconUrl(),
  buttonLogoUrl: getButtonLogoUrl(),
  stripeKey: getStripeKey(),
  cancelColor: '#a63d40',
  subdomain: getSubdomain(),
  fleetPortalCustomization: {}
};

type State = {
  companyName: string,
  logoUrl: string,
  iconUrl: string,
  buttonLogoUrl: string,
  stripeKey: string,
  cancelColor: string,
  subdomain: string,
  fleetPortalCustomization: any
}

type ThemeStateType = {
  type:
  | ThemeActionTypes.API_RESPONSE_SUCCESS
  | ThemeActionTypes.API_RESPONSE_ERROR
  | ThemeActionTypes.SET_THEME
  | ThemeActionTypes.RESET_THEME
  payload: {
    actionType?: string;
    error?: string;
    data?: any;
  }
}

const Theme = (state: State = INIT_STATE, action: ThemeStateType) => {
  switch (action.type) {
    case ThemeActionTypes.API_RESPONSE_SUCCESS: {
      switch (action.payload.actionType) {
        case ThemeActionTypes.SET_THEME: {

          setLocalStorageVariables(
            action.payload.data.company_name,
            action.payload.data.logo,
            action.payload.data.icon,
            action.payload.data.button_logo,
            action.payload.data.stripe,
            action.payload.data.subdomain,
            action.payload.data.domain,
            JSON.stringify(action.payload.data.fleet_portal_customization))

          return {
            ...state,
            companyName: action.payload.data.company_name,
            logoUrl: action.payload.data.logo,
            iconUrl: action.payload.data.icon,
            buttonIconUrl: action.payload.data.button_logo,
            stripeKey: action.payload.data.stripe,
            subdomain: action.payload.data.subdomain,
            fleetPortalCustomization: action.payload.data.fleet_portal_customization
          }
        }
        default:
          return { ...state };
      }
    }
    case ThemeActionTypes.API_RESPONSE_ERROR: {
      switch (action.payload.actionType) {
        case ThemeActionTypes.SET_THEME: {
          return {
            ...state,
            error: action.payload.error
          }
        }
        default:
          return { ...state };
      }
    }

    // Update theme without API
    case ThemeActionTypes.RESET_THEME:
      clearTenantLocalStorageVariables()
      return INIT_STATE
    default:
      return state;
  }
};

export default Theme;
