import { ThemeActionTypes } from './constants';

export type ThemeActionType = {
  type:
  | ThemeActionTypes.API_RESPONSE_SUCCESS
  | ThemeActionTypes.API_RESPONSE_ERROR
  | ThemeActionTypes.SET_THEME
  | ThemeActionTypes.RESET_THEME
  payload: string | {};
};

export const themeApiResponseSuccess = (actionType: string, data: {}): ThemeActionType => ({
  type: ThemeActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const themeApiResponseError = (actionType: string, error: string): ThemeActionType => ({
  type: ThemeActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const setTheme = (): ThemeActionType => {
  return ({
    type: ThemeActionTypes.SET_THEME,
    payload: {}
  })
}

// not api

export const resetTheme = (): ThemeActionType => ({
  type: ThemeActionTypes.RESET_THEME,
  payload: "",
});



