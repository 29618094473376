import { APICore } from 'helpers/api/apiCore';
import { BranchActionTypes } from './constants';
import { FleetBranchType } from 'pages/dashboard/FleetBranchTypes';

const api = new APICore();

const INIT_STATE = {
  branches: [],
  loading: true,
  selectedBranch: {}
};


type FleetActionType = {
  type:
  | BranchActionTypes.API_RESPONSE_SUCCESS
  | BranchActionTypes.API_RESPONSE_ERROR
  | BranchActionTypes.FETCH_BRANCHES
  | BranchActionTypes.FETCH_ALL_PACKAGES
  | BranchActionTypes.SET_SELECTED_BRANCH
  | BranchActionTypes.RESET
  payload: {
    actionType?: string;
    error?: string;
    data?: any;
  };
};

type State = {
  branches: Array<object>,
  loading: boolean,
  selectedBranch: {}
};

const Branches = (state: State = INIT_STATE, action: FleetActionType) => {
  switch (action.type) {
    case BranchActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case BranchActionTypes.FETCH_BRANCHES: {
          return {
            ...state,
            branches: action.payload.data,
            selectedBranch: action.payload.data[0],
            loading: false,
          };
        }
        case BranchActionTypes.FETCH_ALL_PACKAGES: {
          return {
            ...state,
            packages: action.payload.data,
            packagesLoading: false,
          }
        }
        default:
          return { ...state };
      }

    case BranchActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case BranchActionTypes.FETCH_BRANCHES: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }
        default:
          return { ...state };
      }
    case BranchActionTypes.SET_SELECTED_BRANCH: {
      return {
        ...state,
        selectedBranch: action.payload.data
      }
    }
    case BranchActionTypes.RESET:
      return INIT_STATE

    default:
      return { ...state };
  }
};

export default Branches;
