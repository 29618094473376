import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';

// lazy load all the views

// auth
const SplashPage = React.lazy(() => import('pages/splashPage'));
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Register = React.lazy(() => import('pages/account/Register'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));

// dashboard
const FleetDashboard = React.lazy(() => import('pages/dashboard/FleetDashboard'));
const FleetBranch = React.lazy(() => import('pages/dashboard/FleetBranch'));
const FleetBranchesDashboard = React.lazy(() => import('pages/dashboard/FleetBranches'));
const AppointmentsReport = React.lazy(() => import('pages/dashboard/AppointmentsReport'));
const VehiclesReport = React.lazy(() => import('pages/dashboard/VehiclesReport'));
const Profile = React.lazy(() => import('pages/dashboard/Profile'));
const FAQ = React.lazy(() => import('pages/dashboard/FAQ'));
const ContactUs = React.lazy(() => import('pages/dashboard/ContactUs'));
const RequestService = React.lazy(() => import('pages/dashboard/RequestService'));
const Vehicles = React.lazy(() => import('pages/dashboard/Vehicles'));
const VehicleSummary = React.lazy(() => import('pages/dashboard/Vehicles/VehicleSummary'));
const FleetAppointments = React.lazy(() => import('pages/dashboard/FleetAppointments'));
const FleetAppointment = React.lazy(() => import('pages/dashboard/FleetAppointments/FleetAppointment'));
const Photos = React.lazy(() => import('pages/dashboard/Photos'));
const FleetBranchEdit = React.lazy(() => import('pages/dashboard/BranchEditForm'))
const AddVehicle = React.lazy(() => import('pages/dashboard/Vehicles/AddVehicle'))

// payment
const Invoices = React.lazy(() => import('pages/payments/invoices'));
const Invoice = React.lazy(() => import('pages/payments/invoice'));
const Payment = React.lazy(() => import('pages/payments/payments'));
const PaymentManagement = React.lazy(() => import('pages/payments/paymentManagement'));

// apps
const CalendarApp = React.lazy(() => import('pages/apps/Calendar'));

// - chat
const ChatApp = React.lazy(() => import('pages/apps/Chat/'));

// - ecommece pages
const EcommerceProducts = React.lazy(() => import('pages/apps/Ecommerce/Products'));
const ProductDetails = React.lazy(() => import('pages/apps/Ecommerce/ProductDetails'));
const Orders = React.lazy(() => import('pages/apps/Ecommerce/Orders'));
const OrderDetails = React.lazy(() => import('pages/apps/Ecommerce/OrderDetails'));
const Customers = React.lazy(() => import('pages/apps/Ecommerce/Customers'));
const Cart = React.lazy(() => import('pages/apps/Ecommerce/Cart'));
const Checkout = React.lazy(() => import('pages/apps/Ecommerce/Checkout/'));
const Sellers = React.lazy(() => import('pages/apps/Ecommerce/Sellers'));

// error pages
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

// - other
const Maintenance = React.lazy(() => import('pages/other/Maintenance'));
const NewFleetBranch = React.lazy(() => import('pages/other/NewFleetBranch'));

// forms
const BasicForms = React.lazy(() => import('pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('pages/forms/Validation'));
const FormWizard = React.lazy(() => import('pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('pages/forms/FileUpload'));
const Editors = React.lazy(() => import('pages/forms/Editors'));

// charts
const ApexChart = React.lazy(() => import('pages/charts/Apex'));
const ChartJs = React.lazy(() => import('pages/charts/ChartJs'));

// tables
const BasicTables = React.lazy(() => import('pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('pages/tables/Advanced'));

// maps
const GoogleMaps = React.lazy(() => import('pages/maps/GoogleMaps'));
const VectorMaps = React.lazy(() => import('pages/maps/VectorMaps'));


const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {

  return useRoutes([
    { path: '/', element: <Root /> },
    { path: 'loading', element: <LoadComponent component={SplashPage} /> },
    {
      // public routes
      path: '/',
      element: <PrivateRoute roles={'Tenant'} component={DefaultLayout} />,
      children: [
        {
          path: 'account',
          children: [
            { path: 'login', element: <LoadComponent component={Login} /> },
            { path: 'register', element: <LoadComponent component={Register} /> },
            { path: 'confirm', element: <LoadComponent component={Confirm} /> },
            { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
            { path: 'logout', element: <LoadComponent component={Logout} /> },
          ],
        },
        {
          path: '*',
          element: <LoadComponent component={ErrorPageNotFound} />,
        },
        {
          path: 'error-500',
          element: <LoadComponent component={ServerError} />,
        },
        {
          path: 'maintenance',
          element: <LoadComponent component={Maintenance} />,
        },
      ],
    },
    {
      // auth protected routes
      path: '/',
      element: <PrivateRoute roles={'Admin'} component={VerticalLayout} />,
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'fleet_branches',
              element: <LoadComponent component={FleetBranchesDashboard} />,
            },
            {
              path: 'fleet_branch/:id',
              element: <LoadComponent component={FleetBranch} />,
            },
            {
              path: 'invoice/:id',
              element: <LoadComponent component={Invoice} />,
            },
            {
              path: 'fleet_dashboard',
              element: <LoadComponent component={FleetDashboard} />,
            },
            {
              path: 'appointments',
              element: <LoadComponent component={FleetAppointments} />
            },
            {
              path: 'appointment/:id',
              element: <LoadComponent component={FleetAppointment} />
            },
            {
              path: 'appointments_report',
              element: <LoadComponent component={AppointmentsReport} />,
            },
            {
              path: 'vehicles_report',
              element: <LoadComponent component={VehiclesReport} />,
            },
            {
              path: 'profile',
              element: <LoadComponent component={Profile} />,
            },
            {
              path: 'faq',
              element: <LoadComponent component={FAQ} />,
            },
            {
              path: 'contact_us',
              element: <LoadComponent component={ContactUs} />,
            },
            {
              path: 'request_service',
              element: <LoadComponent component={RequestService} />
            },
            {
              path: 'vehicles',
              element: <LoadComponent component={Vehicles} />
            },
            {
              path: 'vehicles/:id',
              element: <LoadComponent component={VehicleSummary} />
            },
            {
              path: 'photos/:id',
              element: <LoadComponent component={Photos} />
            },
            {
              path: 'fleet_branch_edit/:id',
              element: <LoadComponent component={FleetBranchEdit} />
            },
            {
              path: 'add_vehicle',
              element: <LoadComponent component={AddVehicle} />
            },
          ],
        },

        {
          path: 'payments',
          children: [
            {
              path: 'invoices',
              element: <LoadComponent component={Invoices} />
            },
            {
              path: 'payment',
              element: <LoadComponent component={Payment} />
            },
            {
              path: 'payment_management',
              element: <LoadComponent component={PaymentManagement} />
            },
          ]
        },
        {
          path: 'apps',
          children: [
            {
              path: 'calendar',
              element: <LoadComponent component={CalendarApp} />,
            },
            {
              path: 'chat',
              element: <LoadComponent component={ChatApp} />,
            },
            {
              path: 'ecommerce',
              children: [
                {
                  path: 'products',
                  element: <LoadComponent component={EcommerceProducts} />,
                },
                {
                  path: 'details',
                  element: <LoadComponent component={ProductDetails} />,
                },
                {
                  path: 'orders',
                  element: <LoadComponent component={Orders} />,
                },
                {
                  path: 'order/details',
                  element: <LoadComponent component={OrderDetails} />,
                },
                {
                  path: 'customers',
                  element: <LoadComponent component={Customers} />,
                },
                {
                  path: 'shopping-cart',
                  element: <LoadComponent component={Cart} />,
                },
                {
                  path: 'checkout',
                  element: <LoadComponent component={Checkout} />,
                },
                {
                  path: 'sellers',
                  element: <LoadComponent component={Sellers} />,
                },
              ],
            },
          ],
        },
        {
          path: 'pages',
          children: [
            {
              path: 'error-404-alt',
              element: <LoadComponent component={ErrorPageNotFoundAlt} />,
            },
            {
              path: 'invoice',
              element: <LoadComponent component={Invoice} />,
            },
            {
              path: 'new_fleet_branch',
              element: <LoadComponent component={NewFleetBranch} />,
            },
          ],
        },
        {
          path: 'ui',
          children: [
            {
              path: 'forms',
              children: [
                {
                  path: 'basic',
                  element: <LoadComponent component={BasicForms} />,
                },
                {
                  path: 'advanced',
                  element: <LoadComponent component={FormAdvanced} />,
                },
                {
                  path: 'validation',
                  element: <LoadComponent component={FormValidation} />,
                },
                {
                  path: 'wizard',
                  element: <LoadComponent component={FormWizard} />,
                },
                {
                  path: 'upload',
                  element: <LoadComponent component={FileUpload} />,
                },
                {
                  path: 'editors',
                  element: <LoadComponent component={Editors} />,
                },
              ],
            },
            {
              path: 'tables',
              children: [
                {
                  path: 'basic',
                  element: <LoadComponent component={BasicTables} />,
                },
                {
                  path: 'advanced',
                  element: <LoadComponent component={AdvancedTables} />,
                },
              ],
            },
            {
              path: 'charts',
              children: [
                {
                  path: 'apex',
                  element: <LoadComponent component={ApexChart} />,
                },
                {
                  path: 'chartjs',
                  element: <LoadComponent component={ChartJs} />,
                },
              ],
            },
            {
              path: 'maps',
              children: [
                {
                  path: 'googlemaps',
                  element: <LoadComponent component={GoogleMaps} />,
                },
                {
                  path: 'vectormaps',
                  element: <LoadComponent component={VectorMaps} />,
                },
              ],
            },
          ],
        },
      ],
    },
  ]);
};

export { AllRoutes };
