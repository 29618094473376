import { all, fork, put, takeEvery, call, SagaReturnType } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore, setAuthorization } from 'helpers/api/apiCore';
import {
  login as loginApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
  updateUser as updateApi,
  fetchUser as fetchUserApi,
  fetchMetrics as fetchMetricsApi,
  trackVisit
} from 'helpers';
import { authApiResponseSuccess, authApiResponseError, setAuthLoading } from './actions';
import { AuthActionTypes } from './constants';
import { FleetBranch } from 'pages/dashboard/FleetBranch/FleetBranch';

type UserData = {
  payload: {
    firstname: string;
    lastname: string;
    password: string;
    email: string;
    phone: string;
    code: string;
    fleet_branch_id: string;
  };
  type: string;
};

const api = new APICore();


function* login({ payload: { email, password } }: UserData): SagaIterator {
  yield put(setAuthLoading());
  try {
    const response = yield call(loginApi, { email, password });
    const user = response.data;
    if (user["errors"] === null || user["errors"] === undefined) {
      api.setLoggedInUser(user);
      setAuthorization(user.auth_token);
      trackVisit()
      yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
    } else {
      yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, user['errors']));
      api.setLoggedInUser(null);
      setAuthorization(null);
    }

  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* logout(): SagaIterator {
  try {
    localStorage.clear();
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({ payload: { firstname, lastname, email, code, password } }: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, { firstName: firstname, lastName: lastname, email: email, code: code, password: password });
    if (response.data['errors'] == null) {
      const user = response.data;
      api.setLoggedInUser(user);
      setAuthorization(user['auth_token']);
      yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, response));
    } else {
      yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, response.data['error']));
      api.setLoggedInUser(null);
      setAuthorization(null);
    }
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* fetchUser(): SagaIterator {
  try {
    const response = yield call(fetchUserApi);
    if (response.data['errors'] == null) {
      const user = response.data;
      yield put(authApiResponseSuccess(AuthActionTypes.FETCH_USER, user));
    } else {
      yield put(authApiResponseError(AuthActionTypes.FETCH_USER, response.data['error']));
    }
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FETCH_USER, error));
  }
}

function* fetchMetrics({ payload: { fleet_branch_id } }: UserData): SagaIterator {
  yield put(setAuthLoading())
  try {
    const response = yield call(fetchMetricsApi, fleet_branch_id);
    if (response['errors'] == null) {
      yield put(authApiResponseSuccess(AuthActionTypes.FETCH_METRICS, response))
    } else {
      yield put(authApiResponseError(AuthActionTypes.FETCH_METRICS, response.data['error']))
    }
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FETCH_METRICS, error))
  }
}

export function* watchFetchMetrics() {
  yield takeEvery(AuthActionTypes.FETCH_METRICS, fetchMetrics)
}

export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup() {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchFetchUser() {
  yield takeEvery(AuthActionTypes.FETCH_USER, fetchUser);
}

function* authSaga() {
  yield all([fork(watchLoginUser), fork(watchLogout), fork(watchSignup), fork(watchFetchUser), fork(watchFetchMetrics)]);
}


export default authSaga;
