import { ReportsActionTypes } from "./constants";

export type ReportsActionType = {
  type:
  | ReportsActionTypes.API_RESPONSE_SUCCESS
  | ReportsActionTypes.API_RESPONSE_ERROR
  | ReportsActionTypes.FETCH_VEHICLE_REPORT
  | ReportsActionTypes.FETCH_APPOINTMENTS_REPORT
  | ReportsActionTypes.FETCH_BRANCHES
  | ReportsActionTypes.FETCH_PACKAGES
  | ReportsActionTypes.SET_LOADING
  | ReportsActionTypes.RESET
  payload: {} | string;
};

// common success
export const vehiclesReportApiResponseSuccess = (actionType: string, data: {}): ReportsActionType => ({
  type: ReportsActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const vehiclesReportApiResponseError = (actionType: string, error: string): ReportsActionType => ({
  type: ReportsActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const fetchVehiclesReport = (toDate: string | undefined, fromDate: string | undefined, make: string, model: string, vin: string, unit: string, service: string, appointmentId: string, branches: string[] | undefined, packages: string[] | undefined): ReportsActionType => {
  return ({
    type: ReportsActionTypes.FETCH_VEHICLE_REPORT,
    payload: { toDate, fromDate, make, model, vin, unit, service, appointmentId, branches, packages },
  });
}

export const fetchAppointmentsReport = (toDate: string | undefined, fromDate: string | undefined, make: string, model: string, vin: string, unit: string, service: string, appointmentId: string, branches: string[] | undefined, packages: string[] | undefined): ReportsActionType => {
  return ({
    type: ReportsActionTypes.FETCH_APPOINTMENTS_REPORT,
    payload: { toDate, fromDate, make, model, vin, unit, service, appointmentId, branches, packages },
  });
}

export const setLoading = (): ReportsActionType => ({
  type: ReportsActionTypes.SET_LOADING,
  payload: {},
})

export const fetchRBranches = (): ReportsActionType => ({
  type: ReportsActionTypes.FETCH_BRANCHES,
  payload: {},
});

export const fetchRPackages = (): ReportsActionType => ({
  type: ReportsActionTypes.FETCH_PACKAGES,
  payload: {},
});

export const resetVehiclesReport = (): ReportsActionType => ({
  type: ReportsActionTypes.RESET,
  payload: {},
});


