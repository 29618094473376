import { useCallback, useState } from "react";


/**
 * Used with AlertMessage component 
 */
export default function useAlert() {
  const [showAlert, setAlert] = useState(false);
  const [message, setMessage] = useState("Something Went Wrong");
  const [variant, setVariant] = useState("danger");

  const show = useCallback(() => setAlert(true), []);
  const hide = useCallback(() => setAlert(false), []);
  const changeMessage = useCallback((msg: string) => setMessage(msg), []);
  const changeVariant = useCallback((msg: string) => setVariant(msg), [])

  const completeAlert = useCallback((msg: string, show: boolean, variant: string) => {
    setMessage(msg);
    setVariant(variant);
    setAlert(show);
  }, [])

  return { show, hide, changeMessage, changeVariant, showAlert, message, variant, completeAlert }
}