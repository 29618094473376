import { AppointmentActionTypes } from './constants';

export type AppointmentActionType = {
  type:
  | AppointmentActionTypes.API_RESPONSE_SUCCESS
  | AppointmentActionTypes.API_RESPONSE_ERROR
  | AppointmentActionTypes.APPOINTMENTS
  | AppointmentActionTypes.SET_LOADING_APPOINTMENTS
  | AppointmentActionTypes.RESET
  payload: {} | string;
};

// common success
export const appointmentsApiResponseSuccess = (actionType: string, data: {}): AppointmentActionType => ({
  type: AppointmentActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const appointmentsApiResponseError = (actionType: string, error: string): AppointmentActionType => ({
  type: AppointmentActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const setLoadingAppointments = (): AppointmentActionType => ({
  type: AppointmentActionTypes.SET_LOADING_APPOINTMENTS,
  payload: {},
})

export const fetchAppointments = (fleet_branch_id: number): AppointmentActionType => ({
  type: AppointmentActionTypes.APPOINTMENTS,
  payload: { fleet_branch_id },
});

export const resetAppointments = (): AppointmentActionType => {
  return ({
    type: AppointmentActionTypes.RESET,
    payload: {}
  })
}

