export enum ReportsActionTypes {
  API_RESPONSE_SUCCESS = '@@reports/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@reports/API_RESPONSE_ERROR',

  SET_LOADING = '@@reports/SET_LOADING',
  RESET = '@@reports/RESET',
  FETCH_VEHICLE_REPORT = '@@reports/FETCH_REPORT',
  FETCH_APPOINTMENTS_REPORT = '@@reports/FETCH_APPOINTMENTS_REPORT',
  FETCH_BRANCHES = '@@reports/FETCH_BRANCHES',
  FETCH_PACKAGES = '@@reports/FETCH_PACKAGES',


}
