
import { AppointmentActionTypes } from './constants';

const INIT_STATE = {
  appointments: [],
  loading: true,
};

type FleetActionType = {
  type:
  | AppointmentActionTypes.API_RESPONSE_SUCCESS
  | AppointmentActionTypes.API_RESPONSE_ERROR
  | AppointmentActionTypes.SET_LOADING_APPOINTMENTS
  | AppointmentActionTypes.RESET
  payload: {
    actionType?: string;
    error?: string;
    data?: any;
  };
};

type State = {
  appointments: Array<object>,
  loading: boolean,
};

const Appointments = (state: State = INIT_STATE, action: FleetActionType) => {
  switch (action.type) {
    case AppointmentActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AppointmentActionTypes.APPOINTMENTS: {
          return {
            ...state,
            appointments: action.payload.data,
            loading: false,
          };
        }
        default:
          return { ...state };
      }

    case AppointmentActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AppointmentActionTypes.APPOINTMENTS: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }
        default:
          return { ...state };
      }
    case AppointmentActionTypes.SET_LOADING_APPOINTMENTS: {
      return {
        ...state,
        loading: true
      }
    }
    case AppointmentActionTypes.RESET:
      return INIT_STATE
    default:
      return { ...state };
  }
};

export default Appointments;
