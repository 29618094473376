export enum ThemeActionTypes {

  API_RESPONSE_SUCCESS = '@@theme/API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR = '@@theme/API_RESPONSE_ERROR',

  CHANGE_PRIMARY_COLOR = '@@theme/CHANGE_PRIMARY_COLOR',
  CHANGE_SECONDARY_COLOR = '@@theme/CHANGE_SECONDARY_COLOR',
  CHANGE_BACKGROUND_COLOR = '@@theme/CHANGE_BACKGROUND_COLOR',
  CHANGE_PRIMARY_BUTTON_COLOR = '@@theme/CHANGE_PRIMARY_BUTTON_COLOR',
  CHANGE_SECONDARY_BUTTON_COLOR = '@@theme/CHCHANGE_SECONDARY_BUTTON_COLORNGE_PRIMARY_COLOR',
  CHANGE_PRIMARY_BUTTON_TEXT_COLOR = '@@theme/CHANGE_PRIMARY_BUTTON_TEXT_COLOR',
  CHANGE_SECONDARY_BUTTON_TEXT_COLOR = '@@theme/CHANGE_SECONDARY_BUTTON_TEXT_COLOR',

  SET_THEME = '@@theme/SET_THEME',

  RESET_THEME = '@@theme/RESET_THEME'


}