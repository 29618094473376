import { all } from 'redux-saga/effects';
import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import branchSaga from './branches/saga';
import appointmentSaga from './appointments/saga'
import reportSaga from './reports/saga';
import themeSaga from './theme/saga';
import invoiceSaga from './invoices/saga';

export default function* rootSaga() {
  yield all([authSaga(), layoutSaga(), branchSaga(), appointmentSaga(), reportSaga(), themeSaga(), invoiceSaga()]);
}
