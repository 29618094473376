import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  fetchFleetBranches as fetchBranchesAPI,
} from 'helpers';
import { branchApiResponseSuccess, branchApiResponseError, setSelectedBranch } from './actions';
import { BranchActionTypes } from './constants';

// type BranchData = {
//   payload: {
//     name: string,
//     code: string,
//     street: string,
//     street_adtl: string,
//     city: string,
//     state: string,
//     zipcode: string,
//     notes: string,
//   };
//   type: string;
// }

function* fetchFleetBranches(): SagaIterator {
  try {
    const response = yield call(fetchBranchesAPI);
    const fleetResponse = response.data["fleet_branches"]
    // const packageResponse = response.data["packages"]
    yield put(branchApiResponseSuccess(BranchActionTypes.FETCH_BRANCHES, fleetResponse));
    // yield put(fleetApiResponseSuccess(FleetActionTypes.FETCH_ALL_PACKAGES, packageResponse));
  } catch (error: any) {
    yield put(branchApiResponseError(BranchActionTypes.FETCH_BRANCHES, error));
  }
}

export function* watchBranches() {
  yield takeEvery(BranchActionTypes.FETCH_BRANCHES, fetchFleetBranches);
}

function* branchSaga() {
  yield all([fork(watchBranches),]);
}

export default branchSaga;
