import { AuthActionTypes } from './constants';

export type AuthActionType = {
  type:
  | AuthActionTypes.API_RESPONSE_SUCCESS
  | AuthActionTypes.API_RESPONSE_ERROR
  | AuthActionTypes.LOGIN_USER
  | AuthActionTypes.LOGOUT_USER
  | AuthActionTypes.RESET
  | AuthActionTypes.SIGNUP_USER
  | AuthActionTypes.FETCH_USER
  | AuthActionTypes.FETCH_METRICS
  | AuthActionTypes.SET_AUTH_LOADING
  payload: {} | string;
};

type User = {
  id: number;
  email: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
};

// common success
export const authApiResponseSuccess = (actionType: string, data: User | {}): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (email: string, password: string): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { email, password },
});

export const logoutUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

export const signupUser = (firstname: string, lastname: string, email: string, code: string, password: string): AuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: { firstname, lastname, code, email, password },
});


export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});

export const fetchUser = (): AuthActionType => ({
  type: AuthActionTypes.FETCH_USER,
  payload: {},
});

export const fetchMetrics = (fleet_branch_id: number): AuthActionType => {
  return ({
    type: AuthActionTypes.FETCH_METRICS,
    payload: { fleet_branch_id },
  });
}

export const setAuthLoading = (): AuthActionType => ({
  type: AuthActionTypes.SET_AUTH_LOADING,
  payload: {},
});

