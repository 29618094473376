import { MENU_ITEMS, MenuItemType } from 'appConstants';
import config from 'config';

const getMenuItems = (showAppointmentReport?: string) => {
  return MENU_ITEMS;

  // // filter for spiffy tenant, don't show payments for tenants
  // if (!config.IS_SPIFFY_TENANT) {

  //   // Remove invoicing and payment routes for tenants, temp
  //   let newMenuItems = [...MENU_ITEMS]
  //   newMenuItems.splice(2, 1);

  //   if (showAppointmentReport === undefined || showAppointmentReport === "true") {
  //     return newMenuItems;
  //   }

  //   // Remove appointments route if user doesn't have setting to view
  //   newMenuItems[1].children!.forEach((element, index) => {
  //     if (element.label.includes("Appointment")) newMenuItems[1].children!.splice(index, 1)
  //   });

  //   return newMenuItems;
  // } else {
  //   if (showAppointmentReport === undefined || showAppointmentReport === "true") {
  //     return MENU_ITEMS;
  //   }


  //   // Remove appointments route if user doesn't have setting to view
  //   let newMenuItems = [...MENU_ITEMS]
  //   newMenuItems[1].children!.forEach((element, index) => {
  //     if (element.label.includes("Appointment")) newMenuItems[1].children!.splice(index, 1)
  //   });

  //   return newMenuItems;
};

const findAllParent = (menuItems: MenuItemType[], menuItem: MenuItemType): string[] => {
  let parents: string[] = [];
  const parent = findMenuItem(menuItems, menuItem['parentKey']);

  if (parent) {
    parents.push(parent['key']);
    if (parent['parentKey']) parents = [...parents, ...findAllParent(menuItems, parent)];
  }

  return parents;
};

const findMenuItem = (
  menuItems: MenuItemType[] | undefined,
  menuItemKey: MenuItemType['key'] | undefined
): MenuItemType | null => {
  if (menuItems && menuItemKey) {
    for (var i = 0; i < menuItems.length; i++) {
      if (menuItems[i].key === menuItemKey) {
        return menuItems[i];
      }
      var found = findMenuItem(menuItems[i].children, menuItemKey);
      if (found) return found;
    }
  }
  return null;
};

export { getMenuItems, findAllParent, findMenuItem };
