
import { InvoiceActionTypes } from './constants';

const INIT_STATE = {
  invoices: [],
  loading: true,
  unpaid_total: "0",
  overdue_total: "0",
};

type InvoiceActionType = {
  type:
  | InvoiceActionTypes.API_RESPONSE_SUCCESS
  | InvoiceActionTypes.API_RESPONSE_ERROR
  | InvoiceActionTypes.SET_LOADING_INVOICES
  | InvoiceActionTypes.INVOICES
  | InvoiceActionTypes.RESET
  payload: {
    actionType?: string;
    error?: string;
    data?: any;
  };
};

type State = {
  invoices: Array<object>,
  loading: boolean,
  unpaid_total: string,
  overdue_total: string,
};

const Invoices = (state: State = INIT_STATE, action: InvoiceActionType) => {
  switch (action.type) {
    case InvoiceActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case InvoiceActionTypes.INVOICES: {
          return {
            ...state,
            invoices: action.payload.data['invoices'],
            unpaid_total: action.payload.data['unpaid_total'],
            overdue_total: action.payload.data['overdue_total'],
            loading: false,
          };
        }
        default:
          return { ...state };
      }

    case InvoiceActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case InvoiceActionTypes.INVOICES: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }
        default:
          return { ...state };
      }
    case InvoiceActionTypes.SET_LOADING_INVOICES: {
      return {
        ...state,
        loading: true
      }
    }
    case InvoiceActionTypes.RESET:
      return INIT_STATE
    default:
      return { ...state };
  }
};

export default Invoices;
