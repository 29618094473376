import { APICore } from "../../helpers/api/apiCore";
import { ReportsActionTypes } from "./constants";

import { VehicleReportObject, VehicleReportType } from "../../pages/dashboard/VehiclesReport/types";
import { FleetBranchType, Package } from "../../pages/dashboard/FleetBranchTypes";

const api = new APICore();

const INIT_STATE = {
  branches: [],
  packages: [],
  vehicle_report: [],
  appointments_report: [],
  reportLoading: true,
};

type VehiclesReportActionType = {
  type:
  | ReportsActionTypes.API_RESPONSE_SUCCESS
  | ReportsActionTypes.API_RESPONSE_ERROR
  | ReportsActionTypes.FETCH_BRANCHES
  | ReportsActionTypes.FETCH_PACKAGES
  | ReportsActionTypes.FETCH_VEHICLE_REPORT
  | ReportsActionTypes.RESET
  | ReportsActionTypes.SET_LOADING
  payload: {
    actionType?: string;
    error?: string;
    data?: any;
  };
};

type State = {
  branches: Array<FleetBranchType>,
  packages: Array<Package>,
  vehicle_report: Array<VehicleReportType>,
  appointments_report: {}[],
  reportLoading: boolean,
};

const Reports = (state: State = INIT_STATE, action: VehiclesReportActionType) => {
  switch (action.type) {
    case ReportsActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ReportsActionTypes.FETCH_BRANCHES: {
          return {
            ...state,
            branches: action.payload.data,
            reportLoading: false,
          };
        }
        case ReportsActionTypes.FETCH_PACKAGES: {
          return {
            ...state,
            packages: action.payload.data,
            reportLoading: false,
          };
        }
        case ReportsActionTypes.FETCH_VEHICLE_REPORT: {
          return {
            ...state,
            vehicle_report: action.payload.data,
            reportLoading: false,
          }
        }
        case ReportsActionTypes.FETCH_APPOINTMENTS_REPORT: {
          return {
            ...state,
            appointments_report: action.payload.data,
            reportLoading: false,
          }
        }
        default:
          return { ...state };
      }

    case ReportsActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case ReportsActionTypes.FETCH_BRANCHES: {
          return {
            ...state,
            error: action.payload.error,
            reportLoading: false,
          };
        }
        case ReportsActionTypes.FETCH_PACKAGES: {
          return {
            ...state,
            error: action.payload.error,
            reportLoading: false,
          };
        }
        case ReportsActionTypes.FETCH_VEHICLE_REPORT: {
          return {
            ...state,
            error: action.payload.error,
            reportLoading: false,
          }
        }
        case ReportsActionTypes.FETCH_APPOINTMENTS_REPORT: {
          return {
            ...state,
            error: action.payload.error,
            reportLoading: false,
          }
        }
        default:
          return { ...state };
      }

    case ReportsActionTypes.RESET:
      return INIT_STATE;
    case ReportsActionTypes.SET_LOADING:
      return {
        ...state,
        reportLoading: true,
      }
    default:
      return { ...state };
  }
};

export default Reports;
