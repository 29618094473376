import { APICore } from './apiCore';
import axios from 'axios';
import { AppointmentParam, Card, Invoice, Job, PackageAppointmentParams } from 'pages/dashboard/FleetBranchTypes';
import config from '../../config';

const api = new APICore();
const baseUrl = config.API_URL;
const version = config.VERSION;

type dataResponse = {
  data: any;
}

// Users

function login(params: { email: string; password: string }) {
  const url = baseUrl + 'login.json';
  let jsonParams = {
    "user":
    {
      "email": params.email,
      "password": params.password
    }
  }
  return api.create(`${url}`, jsonParams);
}

function fetchUser() {
  const url = baseUrl + 'user.json';
  return api.get(`${url}`, {});
}

function signup(params: { firstName: string, lastName: string, email: string, code: string, password: string }) {

  // let user: { [key: string]: any } =
  // {
  //   "email": params.email,
  //   "password": params.password,
  //   "first_name": params.firstName,
  //   "last_name": params.lastName,
  //   "branch_codes": params.code
  // }

  // Object.keys(user).forEach(k =>
  //   (user[k] && typeof user[k] === 'object') ||
  //   (!user[k] && user[k] !== undefined) && delete user[k]
  // );

  // user = {
  //   "user": user
  // }
  // // const url = baseUrl + 'create_user.json';
  // // return api.create(`${url}`, user);
}

function forgotPassword(params: { email: string }) {
  if (params.email.includes('+')) {
    let temp = params.email.replace('+', '%2B')
    params.email = temp
  }
  const baseUrl = 'forgot_password.json';
  return api.get(`${baseUrl}`, params);
}

function authPasswordToken(params: { token: string, email: string }) {
  let user = {
    "user": {
      "email": params.email,
      "token": params.token
    }
  }
  const baseUrl = 'check_valid_token.json';
  return api.create(`${baseUrl}`, user);
}

function updatePassword(params: { password: string, email: string }) {
  let user = {
    "user": {
      "email": params.email,
      "password": params.password
    }
  }
  const baseUrl = 'update_password.json';
  return api.create(`${baseUrl}`, user);
}

function updateUser(params: { email?: string, first_name?: string, last_name?: string, password?: string, phone?: string }) {
  const url = baseUrl + 'update_user.json';

  let user: { [key: string]: any } =
  {
    "email": params.email,
    "password": params.password,
    "first_name": params.first_name,
    "last_name": params.last_name,
    "phone": params.phone
  }
  // remove empty values
  Object.keys(user).forEach(k =>
    (!user[k] || user[k] === undefined) && delete user[k]
  );

  const jsonParams = {
    "user": user
  }
  return api.create(`${url}`, jsonParams);
}

async function hideVehicle(id: string, fleet_branch_id: string | undefined) {
  const url = baseUrl + 'update_vehicle.json'
  const vehicleParams = {
    'vehicle': {
      'id': id,
      'fleet_branch_id': fleet_branch_id,
      'hidden': true
    }
  }
  return api.create(`${url}`, vehicleParams);
}

async function updateVehicle(
  id: string | undefined,
  fleet_branch_id: string | undefined,
  make: string | undefined,
  model: string | undefined,
  sub_model: string | undefined,
  year: string | undefined,
  vin: string | undefined,
  vehicleType: string | undefined,
  vehicleColor: string | undefined
) {
  const url = baseUrl + 'update_vehicle.json'

  let vehicle: { [key: string]: any } = {
    'id': id,
    'fleet_branch_id': fleet_branch_id,
    'make': make,
    'model': model,
    'sub_model': sub_model,
    'year': year,
    'vin': vin,
    'vehicle_type_id': vehicleType === 'Sedan' ? 1 : 2,
    'color': vehicleColor,
  }

  // remove empty values
  Object.keys(vehicle).forEach(k =>
    (vehicle[k] === undefined || vehicle[k] === "") && delete vehicle[k]
  );

  const vehicleParams = {
    'vehicle': vehicle
  }
  return api.create(`${url}`, vehicleParams);
}



function forgotPasswordConfirm(params: { email: string }) {
  const baseUrl = '/password/reset/confirm/';
  return api.create(`${baseUrl}`, params);
}

// Fleet

function fetchFleetBranches() {
  const url = baseUrl + 'fleet_branches.json';
  return api.get(`${url}`, {});
}

// remove params
function fetchAppointments(params: { fleet_branch_id: string }) {
  const url = baseUrl + 'show_appointments.json';

  return api.get(`${url}`, {})
}

function fetchAppointmentsForBranch(params: { fleet_branch_id: string }) {
  const url = baseUrl + 'show_appointments.json';
  const paramsJSON = {
    fleet_branch_id: params.fleet_branch_id,
    // page: 1,
    // per_page: 8,
    // start_date: '2023-05-14',
    // end_date: '2023-05-21',
  }
  return api.get(`${url}`, paramsJSON)
}

function fetchAppointment(params: { appointment_id: string }) {
  const url = baseUrl + 'appointment.json';
  const paramsJSON = {
    id: params.appointment_id
  }
  return api.get(`${url}`, paramsJSON)
}

function createAppointmentVehicle(params: { appointment: AppointmentParam }) {
  const url = baseUrl + 'create_appointment.json';
  return api.create(`${url}`, params.appointment);
}

function createAppointmentPackage(params: { appointment: PackageAppointmentParams }) {
  const url = baseUrl + 'create_appointment.json';
  return api.create(`${url}`, params.appointment);
}

async function cancelAppointment(params: { appointmentId: number }) {
  const url = baseUrl + `appointments/${params.appointmentId}/cancel`;
  const paramsJSON = {
    appointment_id: params.appointmentId,
    event: "cancel"
  }

  return axios.post(`${url}`, params)
}

function createFleetBranch(name: string, code: string, street: string, street_adtl: string, city: string, state: string, zipcode: string, notes: string) {
  const url = baseUrl + 'create_fleet_branch.json';
  const branchJson = {
    "fleet_branch": {
      "last_name": name,
      "portal_code": code.toLowerCase(),
      "addresses_attributes": [{
        "display_title": street,
        "street": street,
        "city": city,
        "state_abbr": state,
        "postal": zipcode
      }]
    }
  }
  return axios.post(url, branchJson)
}

function deleteFleetBranch(id: number) {
  const url = baseUrl + 'delete_branch.json';
  const branchJson = {
    "fleet_branch": {
      "id": `${id}`
    }
  }
  return api.create(`${url}`, branchJson);
}

const checkHasPayment = async (params: { fleet_branch_id: number }) => {
  const url = baseUrl + 'has_payment_source.json';
  const jsonParams = {
    'fleet_branch_id': params.fleet_branch_id.toString()
  }
  var returnValue = false;
  await api.get(`${url}`, jsonParams).then((res) => {
    if (res.data['has_payment_source'] !== null && res.data['has_payment_source'] === true) {
      returnValue = true;
    } else {
      returnValue = false;
    }
  })
  return returnValue;
}

// fetch multiple vehicles
async function fetchVehicles(params: { id: string }) {
  const url = baseUrl + 'branch_vehicles.json';
  const branchJson = {
    "fleet_branch_id": `${params.id.toString()}`,
    // page: 1,
    // per_page: 8,
  }
  let data;
  await api.get(`${url}`, branchJson).then((res) => {
    data = res.data
  })
  return data;
}

// fetch one
async function fetchVehicle(params: { id: string }) {
  const url = baseUrl + 'vehicle.json';
  const paramsJSON = {
    id: params.id
  }
  return api.get(`${url}`, paramsJSON)
}

async function fetchVehicleHistory(params: { id: string }) {
  const url = baseUrl + 'vehicle_history.json';
  const paramsJSON = {
    id: params.id
  }
  return api.get(`${url}`, paramsJSON)
}

async function fetchPackages(params: { fleet_id: number }) {
  // const url = baseUrl + 'packages.json';
  // const queryParams = {
  //   "fleet_branch_id": `${params.fleet_id}`,
  // }

  // let data;
  // await api.get(url, queryParams).then((res) => {
  //   data = res.data;
  // })
  // return data;

}

async function updateBranchInfo(
  id: string | undefined,
  name: string | undefined,
  code: string | undefined,
  street: string | undefined,
  street_adtl: string | undefined,
  city: string | undefined,
  state: string | undefined,
  zipcode: string | undefined) {
  const url = baseUrl + 'update_branch.json';

  const address: { [key: string]: any } = {
    "display_title": street,
    "street": street,
    "city": city,
    "state_abbr": state,
    "postal": zipcode
  }

  Object.keys(address).forEach((key) => {
    if (address[key] == "" || address[key] == null || address[key] == undefined) delete address[key]
  })

  const branchJson: { [key: string]: any } = {
    "fleet_branch": {
      "id": id,
      "last_name": name,
      "portal_code": code ? code.toLowerCase() : "",
      "addresses_attributes": [Object.keys(address).length != 0 ? address : null]
    }
  }

  Object.keys(branchJson["fleet_branch"]).forEach((key) => {
    if (branchJson["fleet_branch"][key] == "" || branchJson["fleet_branch"][key] == null || branchJson["fleet_branch"][key] == undefined) delete branchJson["fleet_branch"][key]
  })
  const response = await api.create(`${url}`, branchJson)
  return response
}

async function addExistingBranchCode(code: string) {
  const url = baseUrl + 'link_branch.json';
  const branchJson = {
    "fleet_branch": {
      "portal_code": code
    }
  }

  return api.create(`${url}`, branchJson);
}

// Fleet Reports
// Fetches all Branches & Packages for a Fleet App

function fetchBranchesPackages() {
  const url = baseUrl + 'reports/fleet_reports.json';
  return api.get(`${url}`, {});
}

const createCreditCardToken = async ({ exp_month = "", exp_year = "", number = "", zip = "", name = "", cvv = "", fleet_branch_id = "", stripe_key = null }) => {
  const url = baseUrl + 'create_from_token.json';

  // Send to Stripe
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${stripe_key ?? config.STRIPE}`
    },
    body: `card[exp_month]=${exp_month}&card[exp_year]=${exp_year}&card[number]=${number}&card[address_zip]=${zip}&card[name]=${name}&card[cvc]=${cvv}`
  }

  let result = await fetch('https://api.stripe.com/v1/tokens', requestOptions).then(async (response) => {

    if (response.status === 200) {
      let res = await response.json()

      // Send to Conductor
      const cardParams = {
        "card": {
          "fleet_branch_id": fleet_branch_id,
          'card_id': res['id']
        }
      }
      return axios.post(url, cardParams)
    } else {
      let res = await response.json()
      return res
    }
  })

  return result
}

const createVehicle = async ({ fleet_branch_id = "", make = "", model = "", year = "", vin = "", color = "", sub_model = "" }) => {
  make = make.charAt(0).toUpperCase() + make.slice(1);
  model = model.charAt(0).toUpperCase() + model.slice(1);


  var vehicleParam = {
    "vehicle": {
      "fleet_branch_id": fleet_branch_id,
      "make": make,
      "model": model,
      "year": year,
      "vin": vin,
      "color": color,
      "sub_model": sub_model
    }
  }

  const url = baseUrl + 'create_vehicle.json';

  let response = await axios.post(url, vehicleParam)
  return response

}

const fetchVehiclesReport = async (toDate: string, fromDate: string, make: string, model: string, vin: string, unit: string, service: string, appointmentId: string, branches: string[], packages: string[]) => {

  var params: { [key: string]: any } = {
    "from": fromDate,
    "to": toDate,
    "customer_ids": branches,
    "package": packages,
    "appointment_vehicle_id": service,
    "vin": vin,
    "unit_nbr": unit,
    "make": make,
    "model": model,
  }
  Object.keys(params).forEach(k =>
    ((params[k] === undefined) || params[k] === '') && delete params[k]
  )
  const url = baseUrl + 'reports/vehicles/vehicles.json';
  let data;
  await axios.post(`${url}`, params).then((res) => {
    data = res.data
  }).catch((er) => {
    console.log("error: ", er)
  })
  return data;
}

const fetchAppointmentsReport = async (toDate: string, fromDate: string, make: string, model: string, vin: string, unit: string, service: string, appointmentId: string, branches: string[], packages: string[]) => {

  var params: { [key: string]: any } = {
    "start_at": fromDate,
    "end_at": toDate,
    "fleet_branch_id": branches,
    "packages": packages,
    "appointment_vehicle_id": appointmentId,
    "service_id": service,
    "vin": vin,
    "unit_number": unit,
    "make": make,
    "model": model,
  }
  Object.keys(params).forEach(k =>
    ((params[k] === undefined) || params[k] === '') && delete params[k]
  )
  const url = baseUrl + 'reports/services/services.json';
  let data;
  await axios.post(`${url}`, params).then((res) => {
    data = res.data
  }).catch((er) => {
  })
  return data;
}

const fetchMetrics = async (id: string) => {
  const url = baseUrl + 'metrics.json';
  let data;
  let params = {
    fleet_branch_id: id
  }
  await axios.get(`${url}`, { params }).then((res) => {
    data = res.data
  })
  return data;
}

const fetchTenantConfig = async () => {
  const url = config.TENANT_CONFIG_URL + 'tenant_config.json'
  let data;
  await axios.get(`${url}`, { params: config.TENANT }).then((res) => {
    data = res.data
  }).catch((er) => {
    console.log(er)
  })
  return data;
}

const fetchBranch = async (id: string) => {
  const url = baseUrl + 'fleet_branch.json';
  let data;
  let params: any = {
    'fleet_branch_id': id.toString()
  }
  await axios.get(url, { params: params }).then((res) => {
    data = res.data;
  }).catch((er) => {
    data = er;
  })
  return data;
}

const fetchInvoice = async (ids: string[]) => {
  const url = baseUrl + 'invoice.json';
  let data;
  let params: any = {
    'ids': ids
  }
  await axios.get(url, { params: params }).then((res) => {
    data = res.data;
  }).catch((er) => {
    data = false;
  })
  return data;
}

const fetchInvoices = async (fleet_branch_id: string) => {
  const url = baseUrl + 'invoices.json';
  let data;
  let params: any = {
    'fleet_branch_id': fleet_branch_id.toString()
  }
  return axios.get(url, { params: params });
}

const fetchPhotos = async (av_id: string) => {
  const url = baseUrl + 'photos.json';
  const paramsJSON = {
    "av_id": av_id,
  }
  return api.get(`${url}`, paramsJSON)
}

const fetchPaymentMethods = async () => {
  const url = baseUrl + 'cards.json';
  return api.get(`${url}`, {})
}

const submitInvoicePayment = async (invoices: Invoice[]) => {
  const url = baseUrl + 'transaction.json';
  let invoice_numbers = invoices.map((i) => i.invoice_number)
  let params = {
    "payment": {
      invoice_numbers: invoice_numbers
    }
  }
  return axios.post(url, params)
}

const setDefaultCard = async (credit_card_id: string) => {
  const url = baseUrl + 'set_default';
  let params = {
    'card': {
      id: credit_card_id
    }
  }
  return axios.post(url, params)
}

const deleteCard = async (credit_card_id: string) => {
  const url = baseUrl + 'delete_card';
  let params = {
    'card': {
      id: credit_card_id
    }
  }
  return axios.post(url, params)
}

const generateAppointmentSummaryPDF = async (id: string) => {
  const url = config.API_URL + 'summary_pdf.json';
  let params = {
    'appointment_id': id
  }
  return axios.get(url, { responseType: 'arraybuffer', params })
}

const fetchReceiptPdf = async (id: string) => {
  const url = config.API_URL + 'receipt_pdf.json';
  let params = {
    'appointment_id': id
  }
  return axios.get(url, { responseType: 'arraybuffer', params })
}

const generateInvoicePDF = async (id: string) => {
  const url = config.API_URL + 'invoice_pdf.json';
  let params = {
    'ids': [id]
  }
  return axios.get(url, { responseType: 'arraybuffer', params })
}

// update to include other settings(card)
const updateAutoPayFrequency = async (fleet_branch_id: string, frequency?: string, card?: Card) => {
  const url = baseUrl + 'fleet_settings/update.json';
  let settings = [
    {
      setting: "auto_bill_frequency",
      value: frequency
    },
    {
      setting: "auto_bill_credit_card",
      value: card?.id
    }
  ]

  settings = settings.filter((s) => s['value'] !== undefined)
  let params: any = {
    'fleet_settings': {
      fleet_branch_id: fleet_branch_id.toString(),
      settings: settings
    }
  }

  return axios.post(url, params)
}

const cancelAutoPay = async (fleet_branch_id: string) => {
  const url = baseUrl + 'autobill.json';
  let params: any = {
    'fleet_branch': {
      id: fleet_branch_id.toString(),
      autobill: false
    }
  }

  return axios.post(url, params)
}

const enrollAutoPay = async (fleet_branch_id: string, frequency?: string, card?: string) => {
  const url = baseUrl + 'autobill.json';
  let params: any = {
    'fleet_branch': {
      id: fleet_branch_id.toString(),
      frequency: frequency,
      autobill_card_id: card,
      autobill: true
    }
  }

  Object.keys(params).forEach(k =>
    ((params[k] === undefined) || params[k] === '') && delete params[k]
  )


  return axios.post(url, params)
}

// Analaytics

const trackVisit = async () => {
  const url = baseUrl + 'activity/track_visit.json';
  var bodyFormData = new FormData();
  bodyFormData.append('app_name', 'Fleet Portal');
  bodyFormData.append('app_version', '2.0');
  try {
    const response = await fetch(url, { method: 'POST', body: bodyFormData });
    const data = await response.json();
    sessionStorage.setItem('activityToken', data.visit_token)
  } catch (error: any) {
  }
}

const trackEvent = async (name: string, properties?: {}) => {
  const url = baseUrl + 'activity/track_event.json';
  var bodyFormData = new FormData();
  bodyFormData.append('name', name);
  let token = sessionStorage.getItem('activityToken')
  if (token !== null) {
    bodyFormData.append('visit_token', token)
  }
  if (properties !== null && properties !== undefined) {
    bodyFormData.append('properties', JSON.stringify(properties))
  }
  try {
    const response = await fetch(url, { method: 'POST', body: bodyFormData });
    const data = await response.json();
  } catch (error: any) {
  }
}

const updateAllJobs = async (value: boolean, appointment_id: string) => {
  const url = baseUrl + `appointments/${appointment_id}/update_all_jobs?event=${value ? 'approve' : 'reject'}`;

  return axios.post(url);
}

const updateAVJobs = async (value: boolean, appointment_id: string, av_id: string) => {
  const url = baseUrl + `appointments/${appointment_id}/update_av_jobs?event=${value ? 'approve' : 'reject'}&appointment_vehicle_id=${av_id}`;

  return axios.post(url);
}

const updateJob = async (value: boolean, appointment_id: string, job_id: string) => {
  const url = baseUrl + `appointments/${appointment_id}/update_job?event=${value ? 'approve' : 'reject'}&job_id=${job_id}`;

  return axios.post(url);
}

const updateJobStatuses = async (appointment_id: string, jobs: { job: Job, reason: string, notes: string, event: string }[]) => {
  const url = baseUrl + `appointments/${appointment_id}/update_with_reason.json`;
  let new_jobs: any = []
  jobs.forEach(j => {
    let newJob = {
      job_id: j.job.id,
      reason: j.reason,
      notes: j.notes,
      event: j.event
    }
    new_jobs.push(newJob)
  });
  let params = {
    appointment: {
      jobs: new_jobs
    }
  }
  console.log("params: ", params)
  console.log("params: ", JSON.stringify(params))
  return axios.post(url, params);
}

export {
  login,
  signup,
  forgotPassword,
  forgotPasswordConfirm,
  updateUser,
  fetchFleetBranches,
  fetchAppointments,
  createFleetBranch,
  deleteFleetBranch,
  fetchVehicles,
  updateBranchInfo,
  addExistingBranchCode,
  fetchPackages,
  createAppointmentVehicle,
  createAppointmentPackage,
  fetchUser,
  checkHasPayment,
  createCreditCardToken,
  createVehicle,
  fetchBranchesPackages,
  fetchVehiclesReport,
  fetchAppointmentsReport,
  fetchMetrics,
  fetchTenantConfig,
  updateVehicle,
  hideVehicle,
  fetchInvoice,
  cancelAppointment,
  fetchBranch,
  fetchInvoices,
  fetchAppointmentsForBranch,
  fetchAppointment,
  fetchVehicle,
  fetchVehicleHistory,
  fetchPhotos,
  fetchPaymentMethods,
  submitInvoicePayment,
  setDefaultCard,
  deleteCard,
  generateAppointmentSummaryPDF,
  generateInvoicePDF,
  authPasswordToken,
  updatePassword,
  updateAutoPayFrequency,
  cancelAutoPay,
  enrollAutoPay,
  trackVisit,
  trackEvent,
  updateAllJobs,
  updateAVJobs,
  updateJob,
  fetchReceiptPdf,
  updateJobStatuses
};

