import { all, fork, put, takeEvery, call, take } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import {
  fetchAppointmentsForBranch as fetchAppointmentsAPI,
} from 'helpers';
import { appointmentsApiResponseSuccess, appointmentsApiResponseError, setLoadingAppointments } from './actions';
import { AppointmentActionTypes } from './constants';
import { Appointment } from 'pages/dashboard/FleetBranchTypes';

type AppointmentData = {
  payload: {
    fleet_branch_id: string
  },
  type: string
}

function* fetchAppointments({ payload: { fleet_branch_id } }: AppointmentData): SagaIterator {
  try {
    yield put(setLoadingAppointments())
    const response = yield call(fetchAppointmentsAPI, { fleet_branch_id });
    const appointmentsResponse = response.data["appointments"];
    // Looping through appointments that are returned and setting the date to be javascript date objects
    // Hopefully there is a better way to do this but it works for now
    appointmentsResponse.forEach((appointment: Appointment) => {
      // Start Date
      let tempDate = new Date(appointment.start_at);
      appointment.start_at = tempDate;
      // End Date
      tempDate = new Date(appointment.end);
      appointment.end = tempDate;
    });
    yield put(appointmentsApiResponseSuccess(AppointmentActionTypes.APPOINTMENTS, appointmentsResponse));
  } catch (error: any) {
    yield put(appointmentsApiResponseError(AppointmentActionTypes.APPOINTMENTS, error));
  }
}

export function* watchAppointments() {
  yield takeEvery(AppointmentActionTypes.APPOINTMENTS, fetchAppointments);
}

function* appointmentSaga() {
  yield all([fork(watchAppointments),]);
}

export default appointmentSaga;
