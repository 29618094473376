import { BranchActionTypes } from './constants';

export type FleetActionType = {
  type:
  | BranchActionTypes.API_RESPONSE_SUCCESS
  | BranchActionTypes.API_RESPONSE_ERROR
  | BranchActionTypes.FETCH_BRANCHES
  | BranchActionTypes.FETCH_ALL_PACKAGES
  | BranchActionTypes.SET_SELECTED_BRANCH
  | BranchActionTypes.RESET
  payload: {} | string;
};

// common success
export const branchApiResponseSuccess = (actionType: string, data: {}): FleetActionType => ({
  type: BranchActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const branchApiResponseError = (actionType: string, error: string): FleetActionType => ({
  type: BranchActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const fetchBranches = (): FleetActionType => ({
  type: BranchActionTypes.FETCH_BRANCHES,
  payload: {},
});

export const setSelectedBranch = (data: {}): FleetActionType => {
  return ({
    type: BranchActionTypes.SET_SELECTED_BRANCH,
    payload: { data }
  })
}

export const fetchAllPackages = (): FleetActionType => {
  return ({
    type: BranchActionTypes.FETCH_ALL_PACKAGES,
    payload: {},
  });
}

export const resetBranches = (): FleetActionType => {
  return ({
    type: BranchActionTypes.RESET,
    payload: {}
  })
}

