import Routes from 'routes/Routes';

// For Default import Saas.scss
import 'assets/scss/Saas.scss';

import { setAuthorization } from 'helpers/api/apiCore';
import { useEffect } from 'react';
import { fetchTenantConfig } from 'helpers';
import { getCompanyName, setLocalStorageVariables } from 'redux/theme/helper';

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { setTheme } from 'redux/actions';
import { useRedux } from 'hooks';

const initAuth = () => {
  const token = localStorage.getItem("user");
  if (token !== "" && token != null) {
    const jsonToken = JSON.parse(token!);
    setAuthorization(jsonToken["auth_token"]);
  }
}



const injectCustomStyles = (customizationObject: { [x: string]: any; navbar_background: any; sidebar_active: any; }) => {
  const buttonTypes = ['primary', 'secondary', 'success', 'warning', 'danger'];

  const createButtonCss = (type: any) => `
    .btn-${type} {
      --bs-btn-bg: ${customizationObject[`button_${type}`]} !important;
      --bs-btn-border-color: ${customizationObject[`button_${type}`]} !important;
      --bs-btn-hover-bg: ${customizationObject[`button_${type}`]} !important;
      --bs-btn-hover-border-color: ${customizationObject[`button_${type}`]} !important;
    }
  `;

  // Generate button styles
  let cssVars = buttonTypes.map(createButtonCss).join('');

  // Append other custom styles
  cssVars += `
    :root {
      --fleet-sidebar-active-border: ${customizationObject.sidebar_active} !important;
      --bs-primary-bg: ${customizationObject.button_primary} !important;
    }
    a {
      --bs-link-color-rgb: ${customizationObject.link_color} !important;
    }
    .topnav-navbar-dark {
      --fleet-navbar-bg: ${customizationObject.navbar_background} !important;
    }
  `;

  // Create and inject style
  const style = document.createElement('style');
  style.textContent = cssVars;
  document.head.appendChild(style);
};

// if (process.env.NODE_ENV !== 'development') {
//   Sentry.init({
//     dsn: "https://93c7e000311c4550b3acd9ae0c042a23@o127030.ingest.sentry.io/4504803360374784",
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 1.0,
//   });
// }

const App = () => {

  /**
   * Store FPC from local storage before fetching new config,
   * Fetches tenant config and updates local storage and checks if previous FPC was null or undefined,
   * If it was, injects the new styles
   */
  const updateLocalStorageConfig = async () => {
    // Get the currently stored FPC, if any
    const currentFleetPortalCustomization = localStorage.getItem("FleetPortalCustomization")
    const data: any = await fetchTenantConfig();
    if (data && !data.errors) {
      localStorage.setItem("FleetPortalCustomization", JSON.stringify(data.fleet_portal_customization));

      setLocalStorageVariables(
        data.company_name,
        data.logo, data.icon,
        data.button_logo,
        data.stripe,
        data.subdomain,
        data.domain,
        JSON.stringify(data.fleet_portal_customization))

      // If the current FPC is null or undefined, inject the newly retrieved styles
      // There might be an error if data is undefined or null
      if (currentFleetPortalCustomization === null || currentFleetPortalCustomization === "undefined") {
        injectCustomStyles(data.fleet_portal_customization);
      }
    }
  }

  // Updates local storage config variables every 15 minutes
  setInterval(updateLocalStorageConfig, 900000);

  useEffect(() => {

    const fleetPortalCustomization = localStorage.getItem("FleetPortalCustomization");

    // Inject custom styles if they exist, this is called on page load
    if (fleetPortalCustomization !== null && fleetPortalCustomization !== "undefined") {
      const customizationObject = JSON.parse(fleetPortalCustomization as string);
      injectCustomStyles(customizationObject);
    } else {
      // async function to fetch tenant config and update local storage in background
      updateLocalStorageConfig();
    }

  }, [])

  initAuth()
  return <Routes />;
};

export default App;
