import { InvoiceActionTypes } from './constants';

export type InvoiceActionType = {
  type:
  | InvoiceActionTypes.API_RESPONSE_SUCCESS
  | InvoiceActionTypes.API_RESPONSE_ERROR
  | InvoiceActionTypes.INVOICES
  | InvoiceActionTypes.SET_LOADING_INVOICES
  | InvoiceActionTypes.RESET
  payload: {} | string;
};

// common success
export const invoicesApiResponseSuccess = (actionType: string, data: {}): InvoiceActionType => ({
  type: InvoiceActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const invoicesApiResponseError = (actionType: string, error: string): InvoiceActionType => ({
  type: InvoiceActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const setLoadingInvoices = (): InvoiceActionType => ({
  type: InvoiceActionTypes.SET_LOADING_INVOICES,
  payload: {},
})

export const fetchInvoices = (fleet_branch_id: number): InvoiceActionType => ({
  type: InvoiceActionTypes.INVOICES,
  payload: { fleet_branch_id },
});

export const resetInvoices = (): InvoiceActionType => {
  return ({
    type: InvoiceActionTypes.RESET,
    payload: {}
  })
}

