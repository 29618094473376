const getUrl = () => {
  var baseUrl;
  let url = window.location;
  if (url.hostname === "fleet.getspiffy.com") {
    baseUrl = "https://my.getspiffy.com/api/fleet/v1/";
  } else if (url.hostname === "fleetdemo.getspiffy.com") {
    baseUrl = "https://staging.getspiffy.com/api/fleet/v1/";
  } else if (url.hostname === "fleet.getspiffy.dev") {
    baseUrl = "https://mock.getspiffy.com/api/fleet/v1/";
  } else if (url.hostname === "localhost") {
    baseUrl = "https://getspiffy.test/api/fleet/v1/";
    console.log("API: getspiffy.test");
  } else {
    baseUrl = getApiUrlForTenant("tenant");
  }
  return baseUrl;
};

const getUrlTenantConfig = () => {
  var baseUrl;
  let url = window.location;
  if (url.hostname === "fleetdemo.getspiffy.com") {
    baseUrl = "https://staging.getspiffy.com/api/fleet/v1/";
  } else if (url.hostname === "fleet.getspiffy.dev") {
    baseUrl = "https://mock.getspiffy.com/api/fleet/v1/";
  } else if (url.hostname === "localhost") {
    baseUrl = "https://getspiffy.test/api/fleet/v1/";
    console.log("API: getspiffy.test");
  } else {
    baseUrl = "https://my.getspiffy.com/api/fleet/v1/";
  }
  return baseUrl;
};

const getBookingComponentUrl = () => {
  var bookingComponentUrl;
  let url = window.location;
  if (url.hostname == "fleet.getspiffy.com") {
    bookingComponentUrl = "https://my.getspiffy.com/" + process.env.REACT_APP_BOOKING_COMPONENT_API;
  } else if (url.hostname === "fleetdemo.getspiffy.com") {
    bookingComponentUrl = "https://staging.getspiffy.com/" + process.env.REACT_APP_BOOKING_COMPONENT_API
  } else if (url.hostname === 'fleet.getspiffy.dev') {
    bookingComponentUrl = 'https://mock.getspiffy.com/' + process.env.REACT_APP_BOOKING_COMPONENT_API;
  } else if (url.hostname === "localhost") {
    bookingComponentUrl = "https://getspiffy.test/" + process.env.REACT_APP_BOOKING_COMPONENT_API;
  } else {
    bookingComponentUrl = getApiUrlForTenant("booking") + process.env.REACT_APP_BOOKING_COMPONENT_API;
  }
  return bookingComponentUrl;
};

const getApiUrlForTenant = (type: string) => {
  switch (type) {
    case "booking":
      return "https://" + getTenantDomains() + "/";
    case "tenant":
      return "https://" + getTenantDomains() + "/api/fleet/v1/";
    default:
      return "";
  }
}

const getTenantDomains = (): string => {
  let api = localStorage.getItem("subdomain") + "." + localStorage.getItem("domain");
  return api ?? "";
}

const getTenantConfigFromUrl = () => {
  if (window.location.hostname === "localhost") {
    return { "fleet_portal_hostname": "getspiffy.test" }
  }
  else {
    return { 'fleet_portal_hostname': window.location.hostname };
  }
}

const config: any = {
  API_URL: getUrl(),
  TENANT: getTenantConfigFromUrl(),
  TENANT_CONFIG_URL: getUrlTenantConfig(),
  BOOKING_COMPONENT_API: getBookingComponentUrl(),
  STRIPE: localStorage.getItem("stripeKey"),
  VERSION: '2.0'
};

export default config;
